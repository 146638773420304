import React from "react";

import IconWrapper from "@/components/icons/IconWrapper/IconWrapper";
import ImportantCircle from "@/components/icons/ImportantCircle/ImportantCircle";

interface HelperTextProps {
  text?: string;
}

const HelperText = ({ text }: HelperTextProps) => {
  return (
    <>
      {text && (
        <div
          className={`mt-3.5 flex items-center rounded-lg bg-red-100 bg-opacity-75 p-3`}
        >
          <IconWrapper style={{ marginRight: 16 }}>
            <ImportantCircle className={`[&>path]:fill-red`} />
          </IconWrapper>
          <p className={`m-0 text-sm font-bold text-red`}>{text}</p>
        </div>
      )}
    </>
  );
};

export default HelperText;

import React from "react";

import { DropdownProps } from "./Dropdown.types";
import {
  HomepageDropdownLeft,
  HomepageDropdownRight,
} from "./HomepageDropdown.styled";

export default function HomepageDropdown({
  iconLeft,
  options,
  label,
  disabled,
  helperText,
  fill,
  labelClass,
  register,
  name,
  required,
  onChange,
  placeholder = "Kies een optie",
  hasError = false,
  borderColor = "primary",
  multi = false,
  order = "1",
  value,
}: DropdownProps & {
  order: any;
}) {
  if (order === "1") {
    return (
      <HomepageDropdownLeft
        iconLeft={iconLeft}
        options={options}
        label={label}
        disabled={disabled}
        helperText={helperText}
        fill={"#fff"}
        labelClass={labelClass}
        register={register}
        name={name}
        required={required}
        onChange={onChange}
        placeholder={placeholder}
        hasError={hasError}
        borderColor={borderColor}
        multi={multi}
        value={value}
      />
    );
  } else {
    return (
      <HomepageDropdownRight
        iconLeft={iconLeft}
        options={options}
        label={label}
        disabled={disabled}
        helperText={helperText}
        fill={"#fff"}
        labelClass={labelClass}
        register={register}
        name={name}
        required={required}
        onChange={onChange}
        placeholder={placeholder}
        hasError={hasError}
        borderColor={borderColor}
        multi={multi}
        value={value}
      />
    );
  }
}
